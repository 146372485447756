import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import PageHeader from '../components/PageHeader';
import ModularBlocks from '../components/ModularBlocks';

const PlantsForBusinessPage = () => {
  const {
    datoCmsPlantsForBusiness: {
      seoMetaTags,
      title,
      bannerImage,
      bannerLinks,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query PlantsForBusinessPageQuery {
      datoCmsPlantsForBusiness {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          gatsbyImageData(width: 1400, height: 500, layout: FULL_WIDTH)
          alt
        }
        bannerLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
        modularBlocks {
          ...AccordionModularBlockFragment
          ...CompanyReviewsModularBlockFragment
          ...ContactFormModularBlockFragment
          ...ContainedImageModularBlockFragment
          ...ContentModularBlockFragment
          ...ExternalVideoModularBlockFragment
          ...FeaturedProjectsModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...HighlightedTextModularBlockFragment
          ...IconCtasModularBlockFragment
          ...ImageContentModularBlockFragment
          ...ImageGalleryModularBlockFragment
          ...ImageLinkCardsModularBlockFragment
          ...InternalVideoModularBlockFragment
          ...TabsModularBlockFragment
          ...TeamProfilesModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        {bannerImage ? (
          <Banner image={bannerImage} heading={title} links={bannerLinks} />
        ) : (
          <PageHeader heading={title} />
        )}
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default PlantsForBusinessPage;
